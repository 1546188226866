import React, { Suspense, lazy, useState, useEffect } from 'react';
import { Container, CssBaseline, ThemeProvider, createTheme, Box, CircularProgress } from '@mui/material';
import { useAuth } from './contexts/AuthContext';
import MatrixRain from './components/common/MatrixRain';
import { getAuth } from 'firebase/auth';
import './App.css';
import MatrixTitle from './components/common/MatrixTitle';

const LoginPage = lazy(() => import('./components/Auth/LoginPage'));
const CodeConverter = lazy(() => import('./components/CodeConverter/CodeConverter'));
const TokenTracker = lazy(() => import('./components/TokenTracker/TokenTracker'));

const LoadingFallback = () => (
  <Box sx={{ 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '100vh',
    backgroundColor: '#000'
  }}>
    <CircularProgress sx={{ color: '#00ff00' }} />
  </Box>
);

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#00ff00' },
    background: { default: '#000000', paper: '#000000' }
  },
  typography: { fontFamily: 'monospace' },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#00ff00 #000000",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#000000",
            width: 8,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#00ff00",
            border: "2px solid #000000",
          }
        }
      }
    }
  }
});

function App() {
  const { user } = useAuth();
  const [authReady, setAuthReady] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(() => {
      setAuthReady(true);
      setLoading(false);
    });

    // Start loading immediately
    const preloadAuth = async () => {
      try {
        await auth.authStateReady();
      } catch (error) {
        console.error('Auth preload error:', error);
      }
      setLoading(false);
    };
    preloadAuth();

    return () => unsubscribe();
  }, []);

  // Show loading spinner while auth is initializing
  if (loading) {
    return (
      <div className="auth-loading">
        <div className="auth-spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ 
        minHeight: '100vh',
        backgroundColor: '#000',
        position: 'relative',
        overflow: 'hidden',
        padding: { xs: '10px', sm: '20px' }
      }}>
        <MatrixRain />
        <Suspense fallback={<LoadingFallback />}>
          {user ? (
            <Box sx={{ 
              position: 'relative', 
              zIndex: 1,
              minHeight: '100vh',
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '1200px',
              margin: '0 auto',
              width: '100%',
              paddingTop: { xs: '140px', sm: '120px' }
            }}>
              <Box sx={{ 
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                zIndex: 2,
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                alignItems: { xs: 'stretch', sm: 'flex-start' },
                gap: '10px'
              }}>
                <MatrixTitle />
                <TokenTracker />
              </Box>
              <Container maxWidth="lg" sx={{ 
                flex: 1,
                position: 'relative',
                zIndex: 1,
                p: 0
              }}>
                <CodeConverter />
              </Container>
            </Box>
          ) : (
            <LoginPage />
          )}
        </Suspense>
      </Box>
    </ThemeProvider>
  );
}

export default App;
import React, { useEffect, useState } from 'react';
import './MatrixTitle.css';

const MatrixTitle = () => {
  const [text, setText] = useState('');
  const finalText = window.innerWidth <= 768 ? 'CODE\nCONVERT' : 'CODE CONVERT';
  
  useEffect(() => {
    let currentIndex = 0;
    let scrambleInterval;
    
    const scrambleText = () => {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@#$%^&*';
      let scrambled = '';
      const textToUse = finalText.replace('\n', ' ');
      for (let i = 0; i < textToUse.length; i++) {
        if (i < currentIndex) {
          scrambled += textToUse[i];
        } else {
          scrambled += chars[Math.floor(Math.random() * chars.length)];
        }
      }
      setText(window.innerWidth <= 768 ? scrambled.replace(' ', '\n') : scrambled);
    };

    const startScramble = () => {
      scrambleInterval = setInterval(scrambleText, 50);
      
      const revealInterval = setInterval(() => {
        currentIndex++;
        if (currentIndex > finalText.replace('\n', ' ').length) {
          clearInterval(scrambleInterval);
          clearInterval(revealInterval);
          setText(finalText);
        }
      }, 200);
    };

    startScramble();

    return () => {
      clearInterval(scrambleInterval);
    };
  }, [finalText]);

  return (
    <div className="matrix-title">
      <h1>{text}</h1>
    </div>
  );
};

export default MatrixTitle; 